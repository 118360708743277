body {
  font-size: 14px;
  color: $body-color;
}
body.dark {
  color: $body-dark-color;
}
a,
button {
  transition: all 0.4s ease;
}
a {
  color: $primary;
  &:hover {
    color: lighten($primary, 10%);
  }
  &:active {
    color: darken($primary, 10%);
  }
}
hr {
  border: 0;
  border-bottom: 1px solid $border-color;
}
table, img{
  max-width: 100% !important;
}
h1{
  font-size: 2em;
}
table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  thead {
    tr:first-child th:first-child {
      border-radius: 0;
    }
  }
  tr {
    padding: 42px 24px 50px;
    color: #000000d9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    th {
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      position: relative;
      color: #000000d9;
      font-weight: 500;
      text-align: left;
      background: #fafafa;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      transition: background 0.3s ease;
      padding: 16px;
      overflow-wrap: break-word;
    }
  }
  tbody {
    tr {
      td {
        position: relative;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        padding: 16px;
        overflow-wrap: break-word;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        transition: background 0.3s;
      }
    }
    tr:hover {
      background: #fafafa;
    }
  }
}