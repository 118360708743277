// ---------- ant-form
.detail-form {
  .ant-form-item-label > label {
    color: #6e6e6e;

  }
  .ant-form-item-control {
    color: rgba(0, 0, 0, 1);
    body.dark & {
      color: $body-dark-color;
    }
  }
}
.label-on-left .ant-form-item-label {
  text-align: left;
}

// ---------- ant-form-item
.ant-form-item {
  &.invisible-label > .ant-form-item-label {
    visibility: hidden;
    @include mq(max_xs) {
      display: none;
    }
  }
}
.ant-form-item-label {
  white-space: normal;
  line-height: 1.3;
  label {
    font-weight: 500;
  }
}
.is-hide-control-input {
  margin-bottom: 0;
  .ant-form-item-control-input {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }
}

// ---------- ant-input
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: $primary;
}
.ant-input:focus,
.ant-input-focused {
  -webkit-box-shadow: 0 0 0 2px rgba($primary, 0.2);
  box-shadow: 0 0 0 2px rgba($primary, 0.2);
}
.ant-input.only-bd-bottom,
.ant-input-affix-wrapper.only-bd-bottom {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}
.input-gray {
  border-color: #f0f0f0;
  &:hover,
  &:focus {
    border-color: $primary;
  }
  &,
  .ant-input {
    background: #f0f0f0 !important;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f0f0f0 inset !important;
    }
  }
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $primary;
}
.ant-input[readonly] {
  background: #f5f5f5;
  body.dark & {
    background: #1f1f1f;
  }
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $primary;
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: $danger;
}
.ant-input.only-show-text {
  border: 0;
  box-shadow: none;
  background: #fff;
  padding: 0;
}
.has-enter-and-clear-button {
  .ant-input {
    padding-right: 28px;
  }
  .ant-input-search-button {
    padding: 4px 8px;
  }
  .ant-select-clear {
    right: 44px;
  }
}
/* Change the white to any color autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// ---------- ant-checkbox
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}
.ant-checkbox-checked::after {
  border: 1px solid $primary;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary;
}

// ---------- ant-radio
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary;
}
.ant-radio-checked::after {
  border: 1px solid $primary;
}
.ant-radio-inner::after {
  background-color: $primary;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $primary;
}
.ant-radio-button-wrapper:hover {
  color: $primary;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $primary;
  border-color: $primary;
  &:hover:before {
    background-color: darken($primary, 15%);
  }
  &:before {
    background-color: $primary;
  }
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $primary;
  border-color: $primary;
  &:hover {
    background: darken($primary, 15%);
    border-color: darken($primary, 15%);
  }
}

// ---------- ant-select
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $primary;
}
.ant-select.is-read-only .ant-select-selection-item {
  color: $body-color;
}

// ---------- ant-picker-range
.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary;
}
.ant-picker-range .ant-picker-active-bar {
  background: $primary;
}
.ant-picker-range {
  &.w-100 {
    .ant-picker-input {
      input {
        text-align: center;
      }
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $primary;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $primary;
}
.ant-picker-header-view button:hover,
.ant-picker-today-btn {
  color: $primary;
}
.ant-picker.is-read-only .ant-picker-input > input {
  color: $body-color;
}


// ---------- ant-upload
.ant-upload {
  &.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 38px;
  }
  &.ant-upload-select-picture-card:hover {
    border-color: $primary;
  }
}
.c-attachment-list {
  .ant-card {
    &:hover .btn-remove-attachment {
      opacity: 1;
    }
  }
  .ant-card-body {
    padding: 5px;
  }
  .btn-remove-attachment {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
  }
}
